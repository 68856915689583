.cursor-pointer {
  cursor: pointer;
}

.icon-color {
  color: $icon-color;
}

.icon {
  color: $icon-color;
  font-size: 12px;
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  overflow: visible;
}

// .ag-row-odd {
//   background-color: #e9f1fc !important;
// }

.ag-row {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.ag-header-cell {
  color: #6e6e6e !important;
  font-weight: 600 !important;
}

.invalid-feedback {
  margin-top: 0.35rem !important;
  color: #dc3545;
}

.customError {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 1rem;
}
// This class is used in aligning the button to the right side of the Tabs
.customPosition {
  position: absolute;
  top: 7px;
  right: 28px;
}

.customToggle {
  .form-check-input {
    cursor: pointer !important;
  }
}
.ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
  z-index: 0;
}
.ag-row-focus {
  z-index: 999;
}
